<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-truck"/> {{caption}}
        <div class="card-header-actions">
          <CButton color="info" size="sm" @click="storeModal()">Nuevo Producto</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #sat_code="{item}"><td class="w150 text-center">{{item.sat_code}}</td></template>
        <template #unit_name="{item}"><td class="w200 text-center">{{item.unit_name}}</td></template>
        <template #price="{item}">
          <td v-if="item.price == 0" class="w150 text-center">-</td>
          <td v-if="item.price > 0" class="w150 text-right"><span style="float: left">$</span>{{item.price | currency}}</td>
        </template>
        <template #actions="{item}">
          <td class="table_actions b2">
            <CButton color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <ProductModal ref="productModal" @store="storeProcess" @update="updateProcess"></ProductModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import ProductModal from './ProductModal.vue'
import DeleteModal from '../global/DeleteModal.vue'
import ws from '../../services/products';
import store from '../../store'

export default {
  name: 'ProductsTable',
  components: { ProductModal, DeleteModal },
  props: {
    items: Array,
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      fields: [
        {key: 'sat_code', label: 'Código SAT'},
        {key: 'unit_name', label: 'Unidad'},
        {key: 'name', label: 'Nombre'},
        {key: 'price', label: 'Precio'},
        {key: 'actions', label: 'Acciones', class: 'actions' }
      ],
      toasts: []
    }
  },
  methods: {
    storeModal () {
      this.$refs.productModal.storeModal();
    },
    updateModal (item) {
      this.$refs.productModal.updateModal(item);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Producto", item.id, item.name);
    },
    async storeProcess(data) {
      let response = await ws.store(data);
      
      if(response.type == "success"){
        this.$emit("refresh");
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.update(data);

      if(response.type == "success"){
        this.$emit("refresh");
      }

      this.showToast(response.type, response.message);
    },
    async deleteProcess(id) {
      let response = await ws.delete(id);
      
      if(response.type == "success"){
        this.$emit("refresh");
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
